import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import { Button, Grid, Typography } from '@mui/material';
import FastTrackStyles from '../../styles/FastTrackStyles';
import { BaseDialog } from '../../components';
import PoaFillList from './PoaFillList';

function BulkApprovalConfirmationDialog({
  classes,
  open,
  onConfirm,
  onClose,
  poaRequests,
}) {
  const [selectedPoaRequests, setSelectedPoaRequests] = useState([]);

  useEffect(() => {
    setSelectedPoaRequests(poaRequests);
  }, [poaRequests]);

  function handleConfirm() {
    onConfirm(selectedPoaRequests);
  }

  function handleSelected(_, allSelected) {
    setSelectedPoaRequests(allSelected);
  }

  return (
    <BaseDialog
      open={open}
      handleClose={onClose}
      showContractIcon
      purpose="Bulk approval"
      title="Approve Related Requests"
      size="lg"
    >
      <Grid container textAlign="left" direction="column">
        <Grid item>
          <Typography variant="body2" className={`${classes.paper}`}>
            This Power of Attorney Request has{' '}
            <strong>{poaRequests.length} associated entities</strong>.
            <br />
            <br />
            Please select any others that you would like to approve with this request:
          </Typography>
        </Grid>
        <Grid item>
          <PoaFillList
            list={poaRequests}
            getId={(val) => val._id}
            getLabel={(val) =>
              `${val.entityName} (Beneficiary: ${val.beneficiaryFullLegalName})`
            }
            onChecked={handleSelected}
          />
        </Grid>
      </Grid>
      <Grid container item justifyContent="space-around">
        <Grid item>
          <Button onClick={onClose} variant="outlined" fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleConfirm}
            color="secondary"
            variant="contained"
            fullWidth
            disabled={!selectedPoaRequests.length}
          >
            Confirm
          </Button>
        </Grid>
      </Grid>
    </BaseDialog>
  );
}

BulkApprovalConfirmationDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  poaRequests: PropTypes.array.isRequired,
};

export default withStyles(FastTrackStyles)(BulkApprovalConfirmationDialog);
