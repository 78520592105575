import React, { useEffect, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import withStyles from '@mui/styles/withStyles';
import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { useAuthUpdateContext } from '../auth/AuthUpdateProvider';
import { fetchPoaConfig, fetchPoaDocsList } from '../requests/poa';
import { PageContent, PageHeader, PoaTextDetails } from '../components';
import { CheckboxFormik } from './FastTrack/components/CheckboxFormik';
import { DatePickerFormik } from './FastTrack/components/DatePickerFormik';
import { InputFormik } from './FastTrack/components/InputFormik';
import { NumberInputFormik } from './FastTrack/components/NumberInputFormik';
import { AutocompleteFormik } from './FastTrack/components/AutocompleteFormik';
import { FileInputFormik } from './FastTrack/components/FileInputFormik';

import {
  allPrettyKeys,
  beneficiaryKeys,
  boolMap,
  dateInputs,
  dateKeysInitialValues,
  generalDetails,
  generalEntityDetails,
  numberInputs,
  numberKeysInitialValues,
  fileInputs,
  textInputs,
  selectInputs,
  booleanInputs,
  trackingDetails,
  userDetails,
} from './POA/prettyKeyMap';
import { setErrMsg, setSuccessMsg } from '../redux/actions/snackbarActions';
import {
  downloadPoaRequest,
  fetchPoaDoc,
  savePoATrackingDetails,
  postPoaNotify,
  downloadPoaAttachment,
  deletePoa,
} from '../requests/poa';
import { POA_ROUTE } from '../routes.config';
import POAStyles from '../styles/POAStyles';
import { getDateAdjustedStr } from '../utils/FastTrackUtils';
import { downloadFile, downloadJsonAsFile } from '../utils/fileDownload';
import { formatDateToStandard, isDate } from '../utils/parsingUtils';

import ApprovalConfirmationDialog from './POA/ApprovalConfirmationDialog';
import BulkApprovalConfirmationDialog from './POA/BulkApprovalConfirmationDialog';
import AttachmentsList from '../components/PoaTextDetails/AttachmentsList';
import DeleteConfirmationDialog from './POA/DeleteConfirmationDialog';
import * as poaUtils from '../utils/PoaUtils';

const disabledFields = new Set(['regionManagerName', 'attorneyApprovalDate']);

const disabledAfterApproval = new Set(['regionManagerEmail']);

function ViewPoA({ classes }) {
  const { reqId } = useParams();

  const { getAuthHeader, getEmail, hasAnyRole } = useAuthUpdateContext();

  const dispatch = useDispatch();
  const setSuccess = (value) => dispatch(setSuccessMsg(value));
  const setErr = (value) => dispatch(setErrMsg(value));

  const navigate = useNavigate();

  const [doc, setDoc] = useState();
  const [tracking, setTracking] = useState();
  const [regionManagers, setRegionManagers] = useState([]);
  const [selectedRegionManager, setSelectedRegionManager] = useState();

  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [bulkApprovalDialogOpen, setBulkApprovalDialogOpen] = useState(false);
  const [relatedPoas, setRelatedPoas] = useState([]);

  const [approvalNotifyText, setApprovalNotifyText] = useState('Request Approval');
  const [approvalDialogConfirmDisabled, setApprovalDialogConfirmDisabled] =
    useState(false);
  const [deleteDialogConfirmDisabled, setDeleteDialogConfirmDisabled] = useState(false);
  const [approvalSentTo, setApprovalSentTo] = useState([]);

  const userEmail = getEmail();

  const backLink = () => navigate(`${POA_ROUTE}?tab=Paralegal Review`);

  const calendarDaysRange = getDateAdjustedStr(365);

  const formik = useFormik({
    initialValues: {
      comments: '',
      regionManagerApproval: false,
      completedBeforeExpiry: false,
      ...dateKeysInitialValues,
      ...numberKeysInitialValues,
    },
    onSubmit: (values, { setSubmitting }) => {
      setSubmitting(false);
    },
  });
  const inputActions = {
    regionManagerEmail: [
      {
        title: approvalNotifyText,
        disabled:
          approvalSentTo.includes(formik.values.regionManagerEmail) ||
          !!formik.values.regionManagerApproval,
        action: () => {
          setConfirmationDialogOpen(true);
        },
      },
    ],
  };

  useEffect(() => {
    (async () => {
      try {
        const authHeader = await getAuthHeader();
        const poaConfig = await fetchConfig(authHeader);
        const { data, tracking, ...rest } = await fetchPoaDoc(authHeader, reqId);
        setTracking(tracking);
        const docData = {
          ...data,
          ...rest,
        };

        // Set all non-tracking formik fields
        Object.entries(data).forEach(([key, value]) => {
          formik.setFieldValue(key, value);
        });

        // Set tracking keys and handle dates as well
        const dateKeys = new Set(Object.keys(dateKeysInitialValues));
        Object.entries(tracking).forEach(([key, value]) => {
          const dateObj = isDate(value);
          if (dateKeys.has(key) && dateObj) {
            formik.setFieldValue(key, dateObj);
          } else {
            formik.setFieldValue(key, value);
          }
        });

        disabledFields.forEach((disabledKey) => {
          if (docData?.[disabledKey] !== undefined) {
            formik.setFieldValue(disabledKey, docData[disabledKey]);
          }
        });

        const managers = Object.keys(docData.template_metadata.regionManagers).reduce(
          (rms, k) => {
            const regionInfo = docData.template_metadata.regionManagers[k];
            const currentEmails = rms.map((rm) => rm.email);
            if (!currentEmails.includes(regionInfo.email)) {
              rms.push({ ...regionInfo, region: k });
            }
            return rms;
          },
          []
        );
        if (tracking.approvalUser) {
          let approvalManager = managers.find((m) => m.email === tracking.approvalUser);
          if (!approvalManager) {
            approvalManager = {
              name: '',
              email: tracking.approvalUser,
              region: 'approval0',
            };
            managers.unshift(approvalManager);
          }
          setSelectedRegionManager(approvalManager);
          formik.setFieldValue('regionManagerName', approvalManager.name);
        } else {
          setRegionManagers(managers);
          setSelectedRegionManager(
            managers.find((m) => m.email === docData.regionManagerEmail)
          );
        }
        setApprovalSentTo(tracking.approvalSentTo || []);
        setDoc(poaUtils.calculateVirtualFields(poaConfig.template, docData));
      } catch (err) {
        console.error(err);
        setErr('Failed to get the POA request document');
      }
    })();
  }, []);

  async function fetchConfig(authHeader) {
    try {
      const config = await fetchPoaConfig(authHeader);
      return config;
    } catch (err) {
      console.error(err);
      setErr('Failed fetching config for POA');
    }
  }

  function isDisabled(field) {
    if (isApproved() && disabledAfterApproval.has(field)) {
      return true;
    }
    if (disabledFields.has(field)) {
      return true;
    }
    return false;
  }

  function isApproved() {
    return formik.values['regionManagerApproval'];
  }

  function formatValues() {
    const numberKeys = new Set(Object.keys(numberKeysInitialValues));
    return Object.entries(formik.values).reduce((acc, [k, v]) => {
      acc[k] = numberKeys.has(k) ? Number(v) : v;
      return acc;
    }, {});
  }

  function filterUnapproved(poaDocsResponse) {
    // Docs response comes back { completed:[], inProgress: [] }
    const needApproval = [];
    for (const key in poaDocsResponse) {
      const group = poaDocsResponse[key] || [];
      const unapproved = group.filter(
        (poa) => !poa.tracking?.regionManagerApproval && poa._id !== doc?._id
      );
      needApproval.push(...unapproved);
    }
    return needApproval;
  }

  async function handleConfirmBulkApproval(poasToApprove) {
    setBulkApprovalDialogOpen(false);
    saveLink(poasToApprove);
  }

  async function saveLink(relatedPoasToApprove) {
    const formattedValues = formatValues();
    // Set approvalUser
    formattedValues['approvalUser'] = userEmail;
    const { regionManagerApproval } = formattedValues;
    try {
      const headers = await getAuthHeader();
      // Query for other unapproved POAs related to this one
      if (!relatedPoasToApprove && regionManagerApproval) {
        // only do this if its the first pass
        let relatedPoas = [];
        if (doc?.submission_id) {
          relatedPoas = await fetchPoaDocsList(headers, doc.submission_id);
          relatedPoas = filterUnapproved(relatedPoas);
        }

        if (relatedPoas.length) {
          setRelatedPoas(relatedPoas);
          // User selects which POAs to apply to
          return setBulkApprovalDialogOpen(true);
        }
      }
      // Reset bulk vars
      const relatedPoasToApproveIds = (relatedPoasToApprove || []).map((p) => p._id);
      const serverVals = await savePoATrackingDetails(
        headers,
        reqId,
        formattedValues,
        relatedPoasToApproveIds
      );
      // clear files so they don't get re-uploaded
      formik.setFieldValue('attachments', []);
      // Update UI with latest values
      Object.entries(serverVals).forEach(([k, v]) => {
        if (k === 'attachments') {
          // Not a fully controlled component yet
          setDoc((doc) => ({
            ...doc,
            attachments: [...(doc.attachments || []), ...v],
          }));
        } else {
          formik.setFieldValue(k, v);
        }
      });
      setSuccess('Request updated');
    } catch (err) {
      setErr('Failed to update');
      console.error('Failed to update PoA request with error:', err);
    }
  }

  async function notifyByEmail(email) {
    const headers = await getAuthHeader();
    const { data } = await postPoaNotify(headers, reqId, { email, userEmail });
    return data;
  }

  function downloadPageLink() {
    const dateKeys = new Set(Object.keys(dateKeysInitialValues));

    let tmp = {
      ...doc,
      ...formatValues(),
    };

    const cleanedDates = Object.entries(tmp).reduce((acc, [k, v]) => {
      if (dateKeys.has(k) && v !== null) {
        acc[k] = formatDateToStandard(new Date(v));
      } else {
        acc[k] = v;
      }
      return acc;
    }, {});
    tmp = { ...tmp, ...cleanedDates };
    downloadJsonAsFile(tmp, allPrettyKeys, boolMap);
    setSuccess('Summary data downloaded');
  }

  function downloadRequestLink() {
    getAuthHeader()
      .then((headers) => {
        downloadPoaRequest(headers, reqId)
          .then((data) => {
            downloadFile({
              data,
              fileName: `${doc?.submission_id}.zip`,
              fileType: 'multipart/form-data',
            });
            setSuccess('Request data downloaded');
          })
          .catch((err) => {
            setErr('Request data download failed');
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  async function downloadAttachment(attachmentName, submissionId, friendlyName) {
    try {
      const headers = await getAuthHeader();
      const attachment = await downloadPoaAttachment(
        headers,
        submissionId,
        attachmentName
      );
      downloadFile({
        data: attachment,
        fileName: friendlyName,
        fileType: 'multipart/form-data',
      });
      setSuccess('Attachment downloaded');
    } catch (err) {
      setErr('Attachment failed to download');
      console.error(err);
    }
  }

  async function handleApprovalConfirmation({ name, email }) {
    try {
      setApprovalDialogConfirmDisabled(true);
      const {
        tracking: { approvalSentTo },
      } = await notifyByEmail(email);
      setApprovalSentTo(approvalSentTo || []);
      setConfirmationDialogOpen(false);
      setApprovalDialogConfirmDisabled(false);
    } catch (err) {
      setApprovalDialogConfirmDisabled(false);
      console.error(err);
    }
  }

  async function handleDelete() {
    try {
      setDeleteDialogConfirmDisabled(true);
      const headers = await getAuthHeader();
      const { data } = await deletePoa(headers, reqId);
      setDeleteDialogOpen(false);
      setApprovalSentTo(approvalSentTo || []);
      setDeleteDialogConfirmDisabled(false);
      setSuccess('PoA Removed');
      navigate(`${POA_ROUTE}?tab=Paralegal Review`);
    } catch (err) {
      setDeleteDialogConfirmDisabled(false);
      console.error(err);
      setErr('Failed to remove PoA. Check log for details.');
    }
  }

  function handleSelectedRegionalManager(value, key) {
    let valueToSet = null;
    if (typeof value === 'string') {
      valueToSet = { email: value, name: null, region: null };
    } else {
      valueToSet = value;
    }
    setApprovalNotifyText('Request Approval');
    if (!valueToSet || !valueToSet.email) {
      valueToSet =
        regionManagers.find((rm) => formik.values[key] === rm.email) || regionManagers[0];
    }
    formik.setFieldValue(key, valueToSet.email);
    formik.setFieldValue('regionManagerName', valueToSet.name || valueToSet.email);
    setSelectedRegionManager(valueToSet);
  }

  function resubmit() {
    navigate(`${POA_ROUTE}?reqId=${reqId}&renewal=true`);
  }

  return (
    doc && (
      <>
        <PageHeader title={`Viewing: ${doc?.entityName}`}>
          <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item>
              <Button variant="outlined" onClick={backLink}>
                <Typography>Back</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={downloadRequestLink}
                endIcon={<DownloadIcon />}
              >
                <Typography>Download PoA request</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={downloadPageLink}
                endIcon={<DownloadIcon />}
              >
                <Typography>Download this page</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={resubmit}>
                <Typography>Renew</Typography>
              </Button>
            </Grid>
            {hasAnyRole(['poa_manage']) && (
              <Grid item>
                <Button variant="outlined" onClick={() => setDeleteDialogOpen(true)}>
                  <Typography>Delete</Typography>
                </Button>
              </Grid>
            )}
            <Grid item>
              <Button variant="outlined" onClick={() => saveLink()}>
                <Typography>Save</Typography>
              </Button>
            </Grid>
          </Grid>
        </PageHeader>

        <PageContent>
          <div className={classes.outerContainer}>
            <Grid container spacing={6}>
              <Grid item xs={12} md={5}>
                <Stack spacing={2}>
                  <Grid item>
                    <Stack direction="column" spacing={1}>
                      <Grid item>
                        <Typography style={{ fontWeight: 'bold' }}>
                          Tracking Fields:
                        </Typography>
                      </Grid>
                      {dateInputs.map(({ key, title }) => (
                        <Grid item key={`dateInput-${key}`}>
                          <DatePickerFormik
                            formikValues={formik.values}
                            formikErrors={formik.errors}
                            formikTouched={formik.touched}
                            title={`${title}:`}
                            name={key}
                            formikSetFieldValue={formik.setFieldValue}
                            minDate={-1 * calendarDaysRange}
                            maxDate={calendarDaysRange}
                            divWrap={false}
                            disabled={isDisabled(key)}
                          />
                        </Grid>
                      ))}
                      {fileInputs.map(({ key, title }) => (
                        <Grid item key={`fileInput-${key}`}>
                          <FileInputFormik
                            formik={formik}
                            title={`${title}:`}
                            name={key}
                            disabled={isDisabled(key)}
                          />
                        </Grid>
                      ))}
                      {textInputs.map(({ key, title, ...rest }) => (
                        <Grid item key={`textInput-${key}`}>
                          <InputFormik
                            formikValues={formik.values}
                            formikErrors={formik.errors}
                            formikTouched={formik.touched}
                            title={`${title}:`}
                            name={key}
                            formikSetFieldValue={formik.setFieldValue}
                            formikHandleBlur={formik.handleBlur}
                            disabled={isDisabled(key)}
                            actions={inputActions[key]}
                            {...rest}
                          />
                        </Grid>
                      ))}
                      {selectInputs.map(({ key, title }) => (
                        <Grid item key={`autocompleteInput-${key}`}>
                          <AutocompleteFormik
                            freeSolo
                            formikValues={formik.values}
                            formikErrors={formik.errors}
                            formikTouched={formik.touched}
                            title={`${title}:`}
                            name={key}
                            isOptionEqualToValue={(option, value) =>
                              option.email === value.email
                            }
                            formikSetFieldValue={formik.setFieldValue}
                            onChangeOverride={(_, v) => {
                              handleSelectedRegionalManager(v, key);
                            }}
                            onBlur={(e) => {
                              if (
                                selectedRegionManager &&
                                selectedRegionManager.email == e.target.value
                              ) {
                                return;
                              }
                              handleSelectedRegionalManager(e.target.value, key);
                              formik.handleBlur(e);
                            }}
                            disabled={isDisabled(key)}
                            options={regionManagers}
                            getOptionLabel={(o) => o.email}
                            value={selectedRegionManager}
                            actions={inputActions[key]}
                          />
                        </Grid>
                      ))}
                      {booleanInputs.map(({ key, title }) => (
                        <Grid item key={`booleanInput-${key}`}>
                          <CheckboxFormik
                            formikValues={formik.values}
                            formikHandleBlur={formik.handleBlur}
                            setFieldValue={formik.setFieldValue}
                            title={title}
                            name={key}
                          />
                        </Grid>
                      ))}
                      {numberInputs.map(({ key, title }) => (
                        <Grid item key={`numberInput-${key}`}>
                          <NumberInputFormik
                            formikValues={formik.values}
                            formikErrors={formik.errors}
                            formikTouched={formik.touched}
                            title={`${title}:`}
                            name={key}
                            formikSetFieldValue={formik.setFieldValue}
                            formikHandleBlur={formik.handleBlur}
                          />
                        </Grid>
                      ))}
                    </Stack>
                  </Grid>
                </Stack>
              </Grid>
              <Grid item xs={12} md={7}>
                <Stack spacing={2}>
                  <Grid item>
                    <PoaTextDetails
                      title="User Details:"
                      doc={doc}
                      detailsObj={userDetails}
                      selectFields={['user_family_name', 'user_given_name']}
                    />
                  </Grid>
                  <Grid item>
                    <PoaTextDetails
                      title="Selection Details:"
                      doc={doc}
                      detailsObj={generalDetails}
                      selectFields={[
                        'jurisdictionRegion',
                        'newOrRenewal',
                        'scopeType',
                        'scopeSubType',
                        'scopeWording',
                        'specialReqsOrComments',
                      ]}
                    />
                  </Grid>
                  <Grid item>
                    <PoaTextDetails
                      title="Beneficiary Details:"
                      doc={doc}
                      detailsObj={beneficiaryKeys}
                      selectFields={[
                        'beneficiaryFamilyName',
                        'beneficiaryGivenName',
                        'beneficiaryFullLegalName',
                      ]}
                    />
                  </Grid>
                  <Grid item>
                    <PoaTextDetails
                      title="Entity Details (from spreadsheet):"
                      doc={doc}
                      detailsObj={generalEntityDetails}
                      selectFields={[
                        'entityName',
                        'entityEntityType',
                        'entityJurisdictionOfFormation',
                      ]}
                    />
                  </Grid>
                  <Grid item>
                    <PoaTextDetails
                      title="Tracking Details:"
                      doc={tracking}
                      detailsObj={trackingDetails}
                    />
                  </Grid>
                  {!!doc?.attachments?.length && (
                    <Grid item>
                      <AttachmentsList
                        title="Attached Documents"
                        attachments={doc.attachments}
                        submissionId={doc.submission_id}
                        onDownload={downloadAttachment}
                      />
                    </Grid>
                  )}
                </Stack>
              </Grid>
            </Grid>
            <br />
            <Stack spacing={2}>
              <Grid item>
                <PoaTextDetails
                  title="User Details:"
                  doc={doc}
                  detailsObj={userDetails}
                />
              </Grid>
              <Grid item>
                <PoaTextDetails
                  title="Selection Details:"
                  doc={doc}
                  detailsObj={generalDetails}
                />
              </Grid>
              <Grid item>
                <PoaTextDetails
                  title="Beneficiary Details:"
                  doc={doc}
                  detailsObj={beneficiaryKeys}
                />
              </Grid>
              <Grid item>
                <PoaTextDetails
                  title="Entity Details (from spreadsheet):"
                  doc={doc}
                  detailsObj={generalEntityDetails}
                />
              </Grid>
            </Stack>
          </div>
        </PageContent>
        <ApprovalConfirmationDialog
          open={confirmationDialogOpen}
          handleConfirm={handleApprovalConfirmation}
          handleClose={() => setConfirmationDialogOpen(false)}
          name={formik.values.regionManagerName}
          email={formik.values.regionManagerEmail}
          confirmDisabled={approvalDialogConfirmDisabled}
        />
        <DeleteConfirmationDialog
          open={deleteDialogOpen}
          handleDelete={handleDelete}
          handleClose={() => setDeleteDialogOpen(false)}
          poaData={formik.values}
          deleteDisabled={deleteDialogConfirmDisabled}
        />
        <BulkApprovalConfirmationDialog
          open={bulkApprovalDialogOpen}
          onConfirm={handleConfirmBulkApproval}
          onClose={() => setBulkApprovalDialogOpen(false)}
          poaRequests={relatedPoas}
        />
      </>
    )
  );
}

ViewPoA.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(POAStyles)(ViewPoA);
