import axios from 'axios';

import {
  FAST_TRACK_POA_DOC_ENDPOINT,
  FAST_TRACK_POA_ENDPOINT,
  FAST_TRACK_POA_HELP_ENDPOINT,
  FAST_TRACK_POA_LIST_ENDPOINT,
  FAST_TRACK_POA_PENDING_APPROVAL_ENDPOINT,
  FAST_TRACK_POA_DOWNLOAD_ENDPOINT,
  FAST_TRACK_POA_ATTACHMENT_DOWNLOAD_ENDPOINT,
  FAST_TRACK_POA_NOTIFY_ENDPOINT,
  FAST_TRACK_POA_ACTIVEPOA_ENDPOINT,
} from '../endpoints.config';

export async function downloadPoaRequest(headers, id) {
  const { data } = await axios.get(`${FAST_TRACK_POA_DOWNLOAD_ENDPOINT}/${id}`, {
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'blob',
  });
  return data;
}

export async function downloadPoaAttachment(headers, submissionId, attachmentName) {
  const { data } = await axios.get(
    `${FAST_TRACK_POA_ATTACHMENT_DOWNLOAD_ENDPOINT(submissionId, attachmentName)}`,
    {
      headers: {
        ...headers,
        'Content-Type': 'multipart/form-data',
      },
      responseType: 'blob',
    }
  );
  return data;
}

export async function fetchActiveList(headers) {
  const filename = 'active_poas.xlsx';
  const { data } = await axios.get(`${FAST_TRACK_POA_ACTIVEPOA_ENDPOINT}/${filename}`, {
    headers: {
      ...headers,
      'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    },
    responseType: 'blob',
  });
  return data;
}

export async function fetchPoaConfig(headers) {
  const { data } = await axios.get(FAST_TRACK_POA_ENDPOINT, { headers });
  return data;
}

export async function fetchPoaDoc(headers, id) {
  const { data } = await axios.get(`${FAST_TRACK_POA_DOC_ENDPOINT}/${id}`, { headers });
  return data;
}

export async function fetchPoaDocsList(headers, submissionId) {
  const { data } = await axios.get(
    `${FAST_TRACK_POA_LIST_ENDPOINT}${submissionId ? `?submissionId=${submissionId}` : ''}`,
    { headers }
  );
  return data;
}

export async function fetchPoaPendingApprovals(headers) {
  const { data } = await axios.get(FAST_TRACK_POA_PENDING_APPROVAL_ENDPOINT, { headers });
  return data;
}

export async function fetchPoaHelpDoc(headers, filename) {
  const { data } = await axios.get(`${FAST_TRACK_POA_HELP_ENDPOINT}/${filename}`, {
    headers: {
      ...headers,
      'Content-Type': 'multipart/form-data',
    },
    responseType: 'blob',
  });
  return data;
}

export function postPoaNotify(headers, id, data) {
  return axios.post(`${FAST_TRACK_POA_NOTIFY_ENDPOINT}/${id}`, data, { headers });
}

export function deletePoa(headers, id) {
  return axios.delete(`${FAST_TRACK_POA_DOC_ENDPOINT}/${id}`, { headers });
}

export async function savePoATrackingDetails(headers, id, data, relatedIds = []) {
  const roFields = ['attorneyApprovalDate'];

  const trackingFields = [
    'comments',
    'completedBeforeExpiry',
    'poaCompleteDate',
    'regionManagerApproval',
    'sentForSignatureDate',
    'totalParalegalTime',
    'approvalUser',
  ];

  const fileFields = ['attachments'];

  const fd = new FormData();
  Object.entries(data).forEach(([k, v]) => {
    if (trackingFields.includes(k)) {
      fd.append(k, JSON.stringify(v));
    } else if (fileFields.includes(k)) {
      if (v) {
        v.forEach((_f) => fd.append(k, _f.file));
      }
    }
  });
  const {
    data: { updated_files: updatedFiles, updated_mongo: updatedMongo },
  } = await axios.put(`${FAST_TRACK_POA_DOC_ENDPOINT}/${id}`, fd, { headers });

  // Also may be approving other connected POAs
  if (relatedIds?.length) {
    try {
      await saveApprovedForOtherPoas(headers, relatedIds, {
        regionManagerApproval: data.regionManagerApproval,
        approvalUser: data.approvalUser,
      });
    } catch (err) {
      console.error(`Unable to apply approval to other POAS ${otherPoaIds}`);
    }
  }

  return {
    // Return structured data for displaying
    ...[...trackingFields, ...roFields].reduce((acc, field) => {
      const trackingField = `tracking.${field}`;
      // HACK: Unfortunately, not all dates in the schema are type Date
      if (field.includes('Date') && updatedMongo[trackingField]) {
        acc[field] = new Date(updatedMongo[trackingField]);
      } else {
        acc[field] = updatedMongo[trackingField];
      }
      return acc;
    }, {}),
    attachments: updatedFiles,
  };
}

export async function saveApprovedForOtherPoas(headers, ids, data) {
  const trackingFields = ['regionManagerApproval', 'approvalUser'];
  const fd = new FormData();
  Object.entries(data).forEach(([k, v]) => {
    if (trackingFields.includes(k)) {
      fd.append(k, JSON.stringify(v));
    }
  });

  const results = [];
  for (const id of ids) {
    const { data } = await axios.put(`${FAST_TRACK_POA_DOC_ENDPOINT}/${id}`, fd, {
      headers,
    });
    results.push(data);
  }
  return results;
}
