import React, { useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

function PoaFillList({ list, getLabel, getId, onChecked }) {
  const [checked, setChecked] = useState(new Set(list));

  function handleToggle(value) {
    const newChecked = new Set(checked);
    if (newChecked.has(value)) {
      newChecked.delete(value);
    } else {
      newChecked.add(value);
    }
    setChecked(newChecked);
    onChecked(value, Array.from(newChecked));
  }

  return (
    <List sx={{ mb: 4 }}>
      {list.map((value) => {
        const id = getId(value);
        const label = getLabel(value);
        return (
          <ListItem key={id} sx={{ p: 0 }}>
            <ListItemButton onClick={() => handleToggle(value)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={checked.has(value)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': id }}
                />
              </ListItemIcon>
              <ListItemText id={id} primary={label} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

export default PoaFillList;
